import { StorageOptions } from "../syncToPersistentStorage"
import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

export type GoogleMapsSessionToken = {
  sessionToken?: string
}

const sessionStorage: StorageOptions = {
  key: "google-maps-session-token",
  version: "2024-04-04",
  storage: "sessionStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<GoogleMapsSessionToken>({}, sessionStorage)

export {
  getValue as getGoogleMapsSessionToken,
  setValue as setGoogleMapsSessionToken,
  useValue as useGoogleMapsSessionToken,
  mergeValue as mergeGoogleMapsSessionToken,
}
