import {
  GoogleMapsSessionToken,
  mergeGoogleMapsSessionToken,
} from "./googleMapsSessionTokenProperties"

const initialState: GoogleMapsSessionToken = {
  sessionToken: undefined,
}

const googleMapsSessionTokenActions = {
  reset: () => mergeGoogleMapsSessionToken({ ...initialState }),
  setSessionToken: (sessionToken: string) =>
    mergeGoogleMapsSessionToken({ sessionToken }),
}

export default googleMapsSessionTokenActions
