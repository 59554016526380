import { useCallback, useState } from "react"

import {
  GoogleMapsCoordinate,
  GooglePlacePrediction as Prediction,
} from "../../types"

import { GooglePlacesFactory } from "../helpers/initGooglePlaces"
import { useDebouncedSearch } from "src/hooks/useDebouncedSearch"
import googleMapsSessionTokenActions from "src/states/googleMapsSessionToken/googleMapsSessionTokenActions"

type UsePlacesPrediction = () => {
  loading: boolean
  predictions: Prediction[]
  getPredictions: (input: string) => void
  getCoordinates: GetCoordinatesHandler
}

type GetCoordinatesHandler = (placeId: string) => Promise<GoogleMapsCoordinate>

const initGooglePlaces = GooglePlacesFactory()

export const usePlacesPrediction: UsePlacesPrediction = () => {
  const [loading, setLoading] = useState(false)
  const { sessionToken, autocompleteService, geocoder } = initGooglePlaces()

  const getCoordinates: GetCoordinatesHandler = async (placeId) => {
    return geocoder.geocode({ placeId }).then((result) => {
      const { geometry } = result.results[0]
      if (geometry) {
        const { lat, lng } = geometry.location
        return { latitude: lat(), longitude: lng() }
      }
      return {}
    })
  }

  const getPlacePredictions = useCallback(
    async (input: string): Promise<Prediction[]> => {
      if (!input || !sessionToken || input.length < 3)
        return Promise.resolve([])

      setLoading(false)
      const result = await autocompleteService.getPlacePredictions({
        input,
        sessionToken,
        types: ["geocode"],
        componentRestrictions: { country: "de" },
      })
      setLoading(true)
      return result.predictions
    },
    [autocompleteService, sessionToken],
  )

  const { result: predictions, search } = useDebouncedSearch<Prediction[]>(
    getPlacePredictions,
    750,
  )

  const getPredictions = useCallback(search, [search])

  if (sessionToken) {
    googleMapsSessionTokenActions.setSessionToken(
      Object.values(sessionToken)[0] as string,
    )
  }

  return {
    loading,
    predictions: predictions ?? [],
    getPredictions,
    getCoordinates,
  }
}
