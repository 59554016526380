import { FC } from "react"

import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"
import { Modal, ModalContent } from "src/components/common/modal"
import { ROUTES } from "src/config"
import { create } from "src/helpers/bem"
import { serviceIds } from "src/helpers/usercentrics/types"
import { acceptServices } from "src/helpers/usercentrics/utils"
import { useTranslation } from "src/hooks/translation"

import styles from "./GoogleMapsConsentModal.module.scss"

const bem = create(styles, "GoogleMapsConsentModal")

type GoogleMapsConsentModalProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const GoogleMapsConsentModal: FC<GoogleMapsConsentModalProps> = ({
  open,
  onOpenChange,
}) => {
  const { messages, formatMessageRaw } = useTranslation()
  const translations =
    messages.components.common.locationSearch.fakeLocationSearch.modal

  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <ModalContent variant="sm" title={translations.title}>
        <div className={bem()}>
          <p className={bem("description")}>
            {formatMessageRaw(translations.text, {
              privacy: (privacy: string) => (
                <Button
                  variant="tertiary"
                  key={privacy}
                  href={ROUTES.privacy}
                  target="_blank"
                  className={bem("link")}
                >
                  {privacy}
                  <Icon name="externalLinkBlue" className={bem("icon")} />
                </Button>
              ),
            })}
          </p>
          <div className={bem("buttons")}>
            <Button
              variant="primary"
              onClick={() => acceptServices([serviceIds.googleMaps])}
              className={bem("button")}
            >
              {translations.accept}
            </Button>
            <Button
              variant="tertiary"
              className={bem("button")}
              onClick={() => {
                onOpenChange(false)
              }}
            >
              {translations.back}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
