import { useRouter } from "next/router"
import { FC, useState } from "react"

import { Input, InputButtonProps } from "src/components/common/input"

import { ROUTES, serviceFlowSteps } from "src/config"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./FakeLocationSearch.module.scss"
import { GoogleMapsConsentModal } from "./googleMapsConsentModal/GoogleMapsConsentModal"

const bem = create(styles, "FakeLocationSearch")

type FakeLocationSearchProps = {
  query?: string
}

export const FakeLocationSearch: FC<FakeLocationSearchProps> = ({ query }) => {
  const { messages } = useTranslation()
  const translations =
    messages.components.common.locationSearch.fakeLocationSearch

  const router = useRouter()

  const isGaragesPage =
    router.asPath === ROUTES.serviceFlow({ flowStep: serviceFlowSteps.garages })

  const showQuery = !!query && !isGaragesPage

  const [consentModalOpen, setConsentModalOpen] = useState(isGaragesPage)

  const rightButton: InputButtonProps = {
    id: `location-search-current-location-button`,
    icon: "location",
    onClick: () => {
      setConsentModalOpen(true)
    },
  }

  return (
    <div className={bem(undefined, { "garage-page": isGaragesPage })}>
      <Input
        id={"fake-location-search"}
        name={"fake-location-search"}
        role="combobox"
        onFocus={() => {
          setConsentModalOpen(true)
        }}
        rightButton={rightButton}
        placeholder={translations.placeholder}
        defaultValue={showQuery ? query : ""}
      />

      <GoogleMapsConsentModal
        open={consentModalOpen}
        onOpenChange={setConsentModalOpen}
      />
    </div>
  )
}
