// eslint-disable-next-line lodash/import-scope
import { DebouncedFunc, debounce } from "lodash"

import { useEffect, useRef, useState } from "react"

export const useDebouncedSearch = <T>(
  handler: (input: string) => Promise<T>,
  delay: number = 500,
): { result?: T; search: DebouncedFunc<(input: string) => Promise<void>> } => {
  const [result, setResult] = useState<T>()

  const search = useRef(
    debounce(async (input: string) => {
      setResult(await handler(input))
    }, delay),
  ).current

  useEffect(() => {
    return search.cancel()
  }, [search])

  return { result, search }
}
