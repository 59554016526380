import React from "react"
import { useRef } from "react"

import { create } from "src/helpers/bem"
import { messages } from "src/translations/messages/de"

import styles from "./AutocompleteList.module.scss"
import {
  AutocompleteListItem,
  AutocompleteListItemProps,
} from "./AutocompleteListItem"

const bem = create(styles, "AutocompleteList")

export type AutocompleteListProps<T> = {
  id?: string
  query: string
  options: T[]
  hoverIndex: number
  translations: typeof messages.components.common.autocomplete
  onChange: AutocompleteListItemProps<T>["onChange"]
  getOptionLabel: AutocompleteListItemProps<T>["getOptionLabel"]
  renderLabel?: AutocompleteListItemProps<T>["renderLabel"]
  segmentLabels?: AutocompleteListItemProps<T>["segmentLabels"]
}

export const AutocompleteList = <T extends { segment?: string }>({
  id,
  query,
  options,
  getOptionLabel,
  translations,
  hoverIndex,
  onChange,
  renderLabel,
  segmentLabels,
}: AutocompleteListProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const noRecordsLabel = translations.labels.noRecordsFound
  const minCharNeededMessage = translations.labels.minCharNeeded

  const renderList = () => {
    if (query?.length < 3) {
      return <li className={bem("no-records")}>{minCharNeededMessage}</li>
    }

    if (!options.length) {
      return <li className={bem("no-records")}>{noRecordsLabel}</li>
    }

    return options.map((item, index) => (
      <AutocompleteListItem<T>
        key={String(index)}
        option={item}
        query={query}
        renderLabel={renderLabel}
        index={index}
        getOptionLabel={getOptionLabel}
        isHovered={hoverIndex === index}
        onChange={() => onChange(item)}
        segmentLabels={segmentLabels}
      />
    ))
  }

  return (
    <div className={bem()} data-cy="autocomplete-list">
      <div id={`${id}-list`} ref={containerRef} className={bem("list")}>
        <ul role="list">{renderList()}</ul>
      </div>
    </div>
  )
}
