import React, {
  ChangeEvent,
  FocusEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { Input, InputButtonProps } from "src/components/common/input"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import { useHandleClickOutside } from "src/hooks/useHandleClickOutside"

import styles from "./Autocomplete.module.scss"

import { AutocompleteList } from "./components/AutocompleteList"
import { AutocompleteListItemProps } from "./components/AutocompleteListItem"

const bem = create(styles, "Autocomplete")

export type AutocompleteProps<T extends { segment?: string }> = {
  id?: string
  name?: string
  query?: string
  disabled?: boolean
  options: T[]
  inputButton?: InputButtonProps | undefined
  searchMode?: "filter" | "query"
  placeholder?: string
  label?: ReactNode
  error?: string
  hideDropDownIcon?: boolean
  onSelect: (item: T) => void
  selectedItem?: T
  onChangeQuery?: (input: string) => void
  getOptionLabel: AutocompleteListItemProps<T>["getOptionLabel"]
  renderLabel?: AutocompleteListItemProps<T>["renderLabel"]
  segmentLabels?: AutocompleteListItemProps<T>["segmentLabels"]
  onInputBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

/**
 * A very basic implementation of an autocomplete component with client side search
 *
 * Autocomplete filters the dropdown options on type
 * @param options An array of objects of two possible types: T (the default type), or an object with the "segment" paramenter and a keyword to define a segment separation
 * @param segmentLabels An object to map the segment key-value, with the pair keyword-translation.
 *
 * 🧐 See the `brands` file in the `api` folder for reference on `segment` usage
 */
export const Autocomplete = <T extends { segment?: string }>({
  id,
  name,
  error,
  options,
  query,
  disabled = false,
  onChangeQuery,
  inputButton: customInputButton,
  label,
  getOptionLabel,
  placeholder,
  selectedItem,
  searchMode = "filter",
  renderLabel,
  onSelect,
  onInputBlur,
  segmentLabels,
  hideDropDownIcon,
}: AutocompleteProps<T>): JSX.Element => {
  const [searchValue, setSearchValue] = useState(query ?? "")
  const [showDropdown, setShowDropdown] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(-1)

  const autocompleteRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const { messages } = useTranslation()
  const translations = messages.components.common.autocomplete

  const selectedItemLabel = selectedItem
    ? getOptionLabel(selectedItem)
    : undefined

  useEffect(() => {
    const selectedLabel = !selectedItemLabel ? "" : selectedItemLabel
    setSearchValue(selectedLabel)
  }, [selectedItemLabel])

  const filteredOptions = useMemo(() => {
    if (!searchValue) {
      return showDropdown ? options : []
    }

    if (searchMode === "query") {
      return options
    }

    return options.filter((option) => {
      if (option.segment) {
        return option
      }

      return getOptionLabel(option)
        ?.toLowerCase()
        .startsWith(searchValue.toLowerCase())
    })
  }, [searchValue, options, getOptionLabel, searchMode, showDropdown])

  useHandleClickOutside(autocompleteRef, () => {
    setShowDropdown(false)
    setHoverIndex(-1)
  })

  const handleSelect = (item: T) => {
    onSelect(item)
    setShowDropdown(false)
  }

  const handleQueryChange = (query: string) => {
    onChangeQuery?.(query)
    setHoverIndex(-1)
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target
    /** Making first typed character always in uppercase */
    if (value.length === 1) {
      value = value.charAt(0).toUpperCase()
    }
    handleQueryChange(value)
    setSearchValue(value)
    if (!showDropdown) {
      setShowDropdown(true)
    }
  }

  const handleCollapse = () => {
    setShowDropdown(!showDropdown)
    if (!showDropdown) inputRef.current?.focus()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Escape": {
        setSearchValue("")
        setShowDropdown(false)
        handleQueryChange("")
        return
      }

      case "ArrowUp": {
        const currentHover = hoverIndex <= 0 ? 0 : hoverIndex - 1

        if (filteredOptions[currentHover]?.segment) {
          return setHoverIndex(currentHover - 1)
        }

        return setHoverIndex(currentHover)
      }

      case "ArrowDown": {
        if (!showDropdown) handleCollapse()

        const filteredLength = filteredOptions.length - 1
        const currentHover =
          hoverIndex >= filteredLength ? filteredLength : hoverIndex + 1

        if (filteredOptions[currentHover]?.segment) {
          return setHoverIndex(currentHover + 1)
        }

        return setHoverIndex(currentHover)
      }

      case "Enter": {
        event.preventDefault()

        if (hoverIndex !== -1) {
          onSelect(filteredOptions[hoverIndex])
          setHoverIndex(-1)
          setShowDropdown(false)
        }
        return
      }
    }
  }

  const buttonModifiers = {
    "is-opened": showDropdown,
    "is-disabled": disabled,
  }

  const defaultInputButton: InputButtonProps | undefined = !hideDropDownIcon
    ? {
        id: "select-icon",
        icon: "dropdownMenuDown",
        onClick: handleCollapse,
        className: bem("select-icon", buttonModifiers),
      }
    : undefined

  const inputButton: InputButtonProps | undefined =
    customInputButton ?? defaultInputButton

  return (
    <div data-cy={`autocomplete-content-${name}`} ref={autocompleteRef}>
      <Input
        ref={inputRef}
        id={id ?? "autocomplete-input"}
        name={name ?? "autocomplete-input"}
        type="text"
        disabled={disabled}
        error={error}
        role="combobox"
        autoComplete="off"
        onKeyDown={handleKeyDown}
        onFocus={() => setShowDropdown(true)}
        onBlur={onInputBlur}
        rightButton={inputButton}
        className={bem("input")}
        value={query ?? searchValue}
        onChange={onInputChange}
        placeholder={placeholder ?? translations.labels.defaultPlaceholder}
      >
        {label}
      </Input>

      {showDropdown && (
        <AutocompleteList<T>
          id={id}
          query={searchValue}
          translations={translations}
          options={filteredOptions}
          hoverIndex={hoverIndex}
          onChange={handleSelect}
          getOptionLabel={getOptionLabel}
          renderLabel={renderLabel}
          segmentLabels={segmentLabels}
        />
      )}
    </div>
  )
}
