import { MutableRefObject, useEffect } from "react"

export type UseHandleClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  onClickOutside?: () => void,
) => void

export const useHandleClickOutside: UseHandleClickOutside = (
  ref,
  onClickOutside,
) => {
  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      onClickOutside?.()
    }

    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [ref, onClickOutside])
}
