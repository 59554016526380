import React, { useEffect, useRef } from "react"

import { create } from "src/helpers/bem"

import styles from "./AutocompleteList.module.scss"
import { BoldedText } from "./BoldedText"

const bem = create(styles, "AutocompleteList")

export type AutocompleteListItemProps<T> = {
  query: string
  option: T
  isHovered: boolean
  index: number
  onChange: (input: T) => void
  getOptionLabel: (input: T) => string | undefined
  renderLabel?: (p: { item: T; index: number }) => string
  segmentLabels?: { [index: string]: string }
}

export const AutocompleteListItem = <T extends { segment?: string }>({
  query,
  isHovered,
  onChange,
  option,
  index,
  getOptionLabel,
  renderLabel,
  segmentLabels,
}: AutocompleteListItemProps<T>) => {
  const ref = useRef<HTMLLIElement>(null)

  const renderAutocompleteOption = () => {
    if (renderLabel) {
      return renderLabel({ item: option, index })
    }

    const label = getOptionLabel(option)
    if (!label) return null

    return <BoldedText fullText={label} boldText={query ?? ""} />
  }

  useEffect(() => {
    if (isHovered) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      })
    }
  }, [isHovered])

  const modifiers = { "is-hovered": isHovered }

  if (option.segment) {
    if (!segmentLabels) return null
    const label = segmentLabels[option.segment]

    if (!label) return null
    return <li className={bem("segment")}>{label}</li>
  }

  return (
    <li
      ref={ref}
      role="listitem"
      className={bem("item", modifiers, "prediction-item")}
      onClick={() => onChange(option)}
    >
      {renderAutocompleteOption()}
    </li>
  )
}
