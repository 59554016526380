import { useState } from "react"

import { GoogleMapsTypes } from "../../types"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"
import { useTranslation } from "src/hooks/translation"
import { useNotifications } from "src/hooks/useNotifications"

type CurrentLocationType =
  | "DENIED"
  | "NO_GEOLOCATION_PRESENT"
  | GeolocationPosition

type UseCurrentLocationType = (p: {
  onCoordinateChange: GoogleMapsTypes["onCoordinateChange"]
}) => {
  getCurrentLocation: () => Promise<void | google.maps.GeocoderResponse>
  askForCurrentLocation: () => Promise<CurrentLocationType>
  loading: boolean
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}

const getPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

export const useCurrentLocation: UseCurrentLocationType = ({
  onCoordinateChange,
}) => {
  const [loading, setLoading] = useState(false)
  const { messages } = useTranslation()
  const translations =
    messages.components.common.locationSearch.geolocationPositionError
  const { notify } = useNotifications()

  const getCurrentLocation = async () => {
    try {
      const result = await askForCurrentLocation()

      sendTrackingEvent.address({
        gaEvent: { action: "current_location" },
      })

      if (result === "NO_GEOLOCATION_PRESENT") {
        return notify({
          content: translations.noGeolocation,
          variant: "error",
        })
      }

      if (result === "DENIED") {
        return notify({
          content: translations.permissionDenied,
          variant: "error",
        })
      }

      if (result) {
        const { coords } = result
        return await new google.maps.Geocoder().geocode(
          {
            location: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          },
          (result) => {
            const newQuery = result?.[0].formatted_address
            onCoordinateChange(coords, newQuery)
          },
        )
      }
    } catch (error) {
      return notify({
        content: translations.permissionDenied,
        variant: "error",
      })
    }
  }

  const askForCurrentLocation = async () => {
    setLoading(true)

    const { geolocation, permissions } = navigator
    let response: CurrentLocationType | null = null

    if (!geolocation) {
      setLoading(false)
      return "NO_GEOLOCATION_PRESENT"
    }

    try {
      const { state } = await permissions.query({ name: "geolocation" })

      if (state === "denied") {
        response = "DENIED"
      }

      response = await getPosition()

      setLoading(false)
      return response
    } catch (error) {
      setLoading(false)
      return "DENIED"
    }
  }

  return { askForCurrentLocation, loading, getCurrentLocation }
}
