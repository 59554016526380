import React, { FC } from "react"

import { escapeCharacters } from "../helpers/escapeCharacters"

type BoldedTextProps = {
  fullText: string
  boldText: string
}

export const BoldedText: FC<BoldedTextProps> = ({ fullText, boldText }) => {
  const match = fullText.match(escapeCharacters(boldText))

  const matchedString = match?.[0]

  if (!matchedString) return <span>{fullText}</span>

  const bolded = fullText.replace(
    boldText,
    `<b style="font-weight: bold;">${boldText}</b>`,
  )

  const boldedString = String(bolded)

  // The user can't add malicious content, as it needs to match our content to be applied.
  // Check tests.
  return <span dangerouslySetInnerHTML={{ __html: boldedString }} />
}
