import React, { FC, useEffect, useState } from "react"

import {
  GoogleMapsTypes,
  GooglePlacePrediction as Prediction,
} from "../../types"

import { useCurrentLocation } from "../hooks/useCurrentLocation"
import { usePlacesPrediction } from "../hooks/usePlacesPrediction"

import { Autocomplete } from "src/components/common/autocomplete/Autocomplete"
import { InputButtonProps } from "src/components/common/input"
import { create } from "src/helpers/bem"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"
import { useTranslation } from "src/hooks/translation"
import { useServiceFlowData } from "src/states/serviceFlow"

import garageSearchActions from "src/states/serviceFlow/garageSearchActions"

import styles from "./GoogleLocationSearch.module.scss"

const bem = create(styles, "GoogleLocationSearch")

export type GoogleLocationSearchProps = {
  onCoordinateChange: GoogleMapsTypes["onCoordinateChange"]
  query: string
  setQuery: (input: string) => void
  className?: string
  placeholder?: string
}

export const GoogleLocationSearch: FC<GoogleLocationSearchProps> = ({
  query,
  className,
  onCoordinateChange,
  setQuery: onChangeQuery,
  placeholder,
}) => {
  const { garageSearch } = useServiceFlowData()
  const { messages } = useTranslation()
  const translations = messages.components.common.locationSearch

  const [autoSearch, setAutoSearch] = useState(true)
  const { predictions, getCoordinates, getPredictions } = usePlacesPrediction()

  const handleCurrentLocationCoordinatesChange: GoogleMapsTypes["onCoordinateChange"] =
    (coords, query) => {
      onCoordinateChange(coords, query)
      if (query) handleQueryChange(query)
    }

  const { getCurrentLocation } = useCurrentLocation({
    onCoordinateChange: handleCurrentLocationCoordinatesChange,
  })

  const handleQueryChange = (query: string) => {
    getPredictions(query)
    onChangeQuery(query)
  }

  const onInputChange = (value: string) => {
    setAutoSearch(false)
    handleQueryChange(value)
  }

  const onInputBlur = () => {
    sendTrackingEvent.address({
      gaEvent: { action: "text_input" },
    })
  }

  const clearQuery = () => {
    handleQueryChange("")
    setAutoSearch(false)
    garageSearchActions.clearGarageSearch()
  }

  const handleCoordinateChange = (prediction: Prediction) => {
    const updatedQuery = prediction.description
    sendTrackingEvent.address({
      gaEvent: { action: "location_address", value: updatedQuery },
    })
    handleQueryChange(updatedQuery)
    getCoordinates(prediction.place_id).then((coordinate) => {
      onCoordinateChange(coordinate, updatedQuery)
    })
  }

  useEffect(() => {
    if (garageSearch?.query && query !== garageSearch.query) {
      handleQueryChange(garageSearch?.query)
    }
    // should reset query only when the global state is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [garageSearch?.query])

  useEffect(() => {
    if (!garageSearch && query) {
      getPredictions(query)
    }
    // Call the search function when there's a query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (autoSearch && !garageSearch && predictions && predictions.length > 0) {
      handleCoordinateChange(predictions[0])
    }
    // Select the first prediction if predictions are available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictions, garageSearch, autoSearch])

  const inputButton: InputButtonProps = query
    ? {
        id: `location-search-clear-button`,
        ariaLabel: translations.button.clear,
        icon: "deleteTextFill",
        className: bem("icon"),
        onClick: clearQuery,
      }
    : {
        id: `location-search-current-location-button`,
        ariaLabel: translations.button.useCurrentLocation,
        icon: "location",
        onClick: getCurrentLocation,
      }

  return (
    <div className={className}>
      <Autocomplete<Prediction>
        id="google-places"
        name="google-places"
        searchMode="query"
        query={query}
        onChangeQuery={onInputChange}
        inputButton={inputButton}
        placeholder={placeholder || translations.placeholder}
        onSelect={handleCoordinateChange}
        options={predictions}
        getOptionLabel={(prediction) => prediction.description}
        onInputBlur={onInputBlur}
      />
    </div>
  )
}
