import { Status, Wrapper } from "@googlemaps/react-wrapper"

import { FC } from "react"

import { Loader } from "../../loader"
import { GoogleMapsTypes } from "../types"
import { FakeLocationSearch } from "src/components/common/googleMaps/fakeLocationSearch"
import { useEnvironmentVariables } from "src/components/core/providers/EnvironmentVariablesProvider"

import { useHasServiceConsent } from "src/helpers/usercentrics/hooks/useHasServiceConsent"
import { serviceIds } from "src/helpers/usercentrics/types"
import { LOCALE } from "src/translations"

import { GoogleLocationSearch } from "./components/GoogleLocationSearch"

type LocationSearchProps = {
  onCoordinateChange: GoogleMapsTypes["onCoordinateChange"]
  query: string
  setQuery: (query: string) => void
  className?: string
  placeholder?: string
}

export const LocationSearch: FC<LocationSearchProps> = ({
  onCoordinateChange,
  query,
  setQuery,
  className,
  placeholder,
}) => {
  const locale = LOCALE
  const { googleMapsApiKey } = useEnvironmentVariables()

  const hasGoogleMapsConsent = useHasServiceConsent(serviceIds.googleMaps)

  if (!googleMapsApiKey) {
    return null
  }

  if (!hasGoogleMapsConsent) {
    return <FakeLocationSearch query={query} />
  }

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <Loader />
      case Status.FAILURE:
        // No error component is available from design yet
        return <div>Error</div>
      case Status.SUCCESS:
        return (
          <GoogleLocationSearch
            onCoordinateChange={onCoordinateChange}
            query={query}
            setQuery={setQuery}
            className={className}
            placeholder={placeholder}
          />
        )
    }
  }

  return (
    <Wrapper
      apiKey={googleMapsApiKey}
      language={locale}
      region={locale}
      libraries={["places"]}
      render={render}
    />
  )
}
