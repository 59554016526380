import { mergeServiceFlowData } from "./serviceFlow"

export type GarageSearch = {
  query?: string
  coordinates?: {
    latitude?: number
    longitude?: number
  }
}

const garageSearchActions = {
  clearGarageSearch: () => mergeServiceFlowData({ garageSearch: undefined }),

  setGarageSearchData: (garageSearch: GarageSearch) =>
    mergeServiceFlowData({ garageSearch }),
}

export default garageSearchActions
