import {
  GooglePlacesGeocoder,
  GoogleAutocompleteService,
  GoogleAutocompleteSessionToken,
} from "../../types"

type GooglePlacesFactoryType = () => () => {
  geocoder: GooglePlacesGeocoder
  sessionToken: GoogleAutocompleteSessionToken
  autocompleteService: GoogleAutocompleteService
}

export const GooglePlacesFactory: GooglePlacesFactoryType = () => {
  let geocoder: GooglePlacesGeocoder
  let sessionToken: GoogleAutocompleteSessionToken
  let autocompleteService: GoogleAutocompleteService

  return () => {
    if (!sessionToken) {
      const { AutocompleteSessionToken, AutocompleteService } =
        google.maps.places

      geocoder = new google.maps.Geocoder()
      sessionToken = new AutocompleteSessionToken()
      autocompleteService = new AutocompleteService()
    }

    return { sessionToken, autocompleteService, geocoder }
  }
}
